.site-footer {
    background-color: #336CFF;
    height: 15px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    font-family: "Trebuchet MS", Arial, Helvetica, san-serif;

    .inner {
        font-weight: 500;
        color: red;
    }
}